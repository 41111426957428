.mainContent {
  width: 850px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 730px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.deviceBox {
  display: block;
  flex-grow: 1;
  overflow: auto;
}
.brand {
  width: 65%;
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin: 0 auto;
}
.brand > div {
  text-align: center;
  width: 33.33333%;
}
.brand > div:nth-child(2):before {
  content: "";
  position: absolute;
  display: block;
  height: 60%;
  width: 1px;
  border-left: 1px solid #FFF;
  top: 20%;
  left: 0;
}
.brand > div:nth-child(2):after {
  content: "";
  position: absolute;
  display: block;
  height: 60%;
  width: 1px;
  border-right: 1px solid #FFF;
  top: 20%;
  right: 0;
}
