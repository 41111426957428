
@import "../../variables.less";
@import "../../resource/common.less";

@currentWidth: 850px;
@attachWidth: 730px;
.deviceBox {
	display: block;
	flex-grow: 1;
	overflow: auto;
}

.brand {
	width: 65%;
	display: flex;
	flex-direction: row;
	padding: @smallSpace;
	margin: 0 auto;

	> div {
		text-align: center;
		width: 33.33333%;

		&:nth-child(2) {
			&:before {
				content: "";
				position: absolute;
				display: block;
				height: 60%;
				width: 1px;
				border-left: 1px solid #FFF;
				top: 20%;
				left: 0;
			}

			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 60%;
				width: 1px;
				border-right: 1px solid #FFF;
				top: 20%;
				right: 0;
			}
		}
	}
}
