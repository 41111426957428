@currentWidth: 400px;
@attachWidth: 150px;
.mainContent {
  width: @currentWidth;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: @mainBackground;
  padding: @smallSpace;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
  .head {
	line-height: 32px;
	font-size: 18px;
	flex-shrink: 0;
	color: #FFF;
	.attach {
	  width: @attachWidth;
	  float: right;
	}
  }

  .blockTitle {
	font-size: @fontSizeC;
	margin-left: @middleSpace;
	border-bottom: 1px solid #FFF;
	color: white;
  }
}
// 公共按钮样式~
.left_click_btn{
    position:absolute;
    top: 48%;
    left: -18px;
    // background: linear-gradient(180deg, var(--themeColor)0%, var(--themeColor)100%);
    background: var(--themeColor);
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0px;
    border-radius: 4px 0 0 4px;
    cursor:pointer;
    img{
        width: 18px;
        transform: rotate(180deg);

    }
}
.left_click_btn_active{
    transition: all 2s ease;
    img{
        transform: rotate(0deg);

    }
}
// 公共
@currentWidth2: 750px;
.mainContent2,.index{
    position:absolute;
    right: 0;
    top: 60px;
    height: calc(100vh - 60px);
    width: @currentWidth2;
    background: @mainBackground;
    pointer-events: all;
    transition: right 1s ease;
    .mian_title{
        font-size: 18px;
        width:100%;
        padding: 10px 10px;
        display:flex;
        font-weight: 600;
        position:relative;
        margin: 0 0 0 10px;
        line-height: 18px;
        &:before{
            content:"";
            position:absolute;
            width: 2px;
            height: 18px;
            top:11px;
            background: @baseColor;
            left: 1px;
        }
    }
    .gary_line{
        width: calc(100% - 20px);
        height: 2px;
        position:relative;
        left: 10px;
        bottom:0;
        background-color: rgb(247 247 250);
        &::after{
            content:"";
            position:absolute;
            width: 8px;
            height: 2px;
            top: 0px;
            background: #e0e2e3;
            right: 0;
        }
        &::before{
            content:"";
            position:absolute;
            width: 8px;
            height: 2px;
            top: 0;
            background: #e0e2e3;
            left: 0;
        }
    }
}
